<template>
  <div class="cumulative-container">
    <CSBreadcrumb style="overflow: hidden"></CSBreadcrumb>
    <template v-if="quer.id == 1 || quer.id == 3">
      <div class="filter-panel">
        <CSSelect i-width="36rpx" style="width: 230px; margin-right: 0">
          <el-date-picker
              type="datetime"
              placeholder="请选择开始时间"
              v-model="cumulativeList.startTime"
              style="width: 214px"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              prefix-icon="el-icon-time"
              :editable="false"
          >
          </el-date-picker>
        </CSSelect>
        <span class="inline-gray"> </span>
        <CSSelect i-width="36rpx" style="width: 230px">
          <el-date-picker
              type="datetime"
              placeholder="请选择结束时间"
              v-model="cumulativeList.endTime"
              style="width: 214px"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              prefix-icon="el-icon-time"
              :editable="false"
          >
          </el-date-picker>
        </CSSelect>
        <button class="btn btn-primary" @click="getLogList()">查询</button>
      </div>
      <div class="result-panel">
        <CSTable :thead-top="60">
          <template v-slot:header>
            <div
                class="table-header-panel text-left"
                style="
                            font-size: 14px;
                            font-family: PingFang SC;
                            font-weight: 400;
                            line-height: 20px;
                            color: #000000;
                            opacity: 1;
                        "
                v-if="companyName"
            >
              {{ quer.existence }}:<span>{{ companyName }}</span>
            </div>
          </template>
          <template v-slot:thead>
            <tr>
              <th>日期</th>
              <th>水表编号</th>
              <th>关联房间</th>
              <th>使用吨数</th>
              <th>水价(元/吨)</th>
              <th>消耗金额</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <tr v-for="(item, index) in waterList.datas" :key="index">
              <td class="date-td">
                <span>{{ item.date }}</span>
              </td>
              <td>
                <span>{{ item.imei }}</span>
              </td>
              <td>
                <span>{{ item.correlationRoom }}</span>
              </td>
              <td>
                <span>{{ item.num }}</span>
              </td>
              <td>
                <span>{{ item.producePrice || '-' }}</span>
              </td>
              <td>
                <span>{{ item.amount }}</span>
              </td>
            </tr>
          </template>
        </CSTable>
        <Pagination name="pagination" componentName="Pagination"></Pagination>
      </div>
    </template>
    <template v-if="quer.id == 2 || quer.id == 4">
      <div class="filter-panel">
        <CSSelect i-width="36rpx" style="width: 230px; margin-right: 0">
          <el-date-picker
              type="datetime"
              placeholder="请选择开始时间"
              v-model="cumulativeList.startTime"
              style="width: 214px"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              prefix-icon="el-icon-time"
              :editable="false"
          >
          </el-date-picker>
        </CSSelect>
        <span class="inline-gray"> </span>
        <CSSelect i-width="36rpx" style="width: 230px">
          <el-date-picker
              type="datetime"
              placeholder="请选择结束时间"
              v-model="cumulativeList.endTime"
              style="width: 214px"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              prefix-icon="el-icon-time"
              :editable="false"
          >
          </el-date-picker>
        </CSSelect>
        <button class="btn btn-primary" @click="getLogList()">查询</button>
      </div>
      <div class="result-panel">
        <CSTable :thead-top="60">
          <template v-slot:header>
            <div
                class="table-header-panel text-left"
                style="
                          font-size: 14px;
                          font-family: PingFang SC;
                          font-weight: 400;
                          line-height: 20px;
                          color: #000000;
                          opacity: 1;
                       "
                v-if="companyName"
            >
              {{ quer.existence }}:<span>{{ companyName }}</span>
            </div>
          </template>
          <template v-slot:thead>
            <tr>
              <th>日期</th>
              <th>电表编号</th>
              <th>关联房间</th>
              <th>使用度数</th>
              <th>电价（元/度）</th>
              <th>消耗金额</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <tr v-for="item in electricityList" :key="item.date">
              <td class="date-td">
                {{ item.date }}
              </td>
              <td>
                {{ item.imei }}
              </td>
              <td>
                {{ item.correlationRoom }}
              </td>
              <td>
                {{ item.num }}
              </td>
              <td>
                {{ item.producePrice || '-'}}
              </td>
              <td>
                {{ item.amount }}
              </td>
            </tr>
          </template>
        </CSTable>

        <Pagination name="pagination" componentName="Pagination"></Pagination>
      </div>
    </template>
  </div>
</template>

<script>
import CSBreadcrumb from "@/components/common/CSBreadcrumb";
import CSTabBar from "@/components/common/CSTabBar";
import Pagination from "@/components/Pagination.vue";
import CSSelect from "@/components/common/CSSelect";
import {waterUmulativeConsumptionUrl} from "@/requestUrl";
import CSTable from "@/components/common/CSTable";

export default {
  activated() {
    window.addEventListener("keydown", this.getLogListDown);
  },
  deactivated() {
    window.removeEventListener('keydown', this.getLogListDown);
  },
  data() {
    return {
      cumulativeList: {
        startTime: dayjs().subtract(30, "day").format("YYYY-MM-DD") + " 00:00",
        endTime: dayjs().format("YYYY-MM-DD") + " 23:59",
      },
      waterList: [],
      electricityList: [],
      //接收信息
      quer: null,
      companyName: "",
      rid: ""
    };
  },
  mounted() {
    this.$vc.on(this.$route.path, "pagination", "page_event", (pageNo) => {
      this.getLogList(pageNo);
    });
  },
  created() {
    this.quer = this.$route.params;
    this.companyName = this.quer.companyName;
    this.rid = this.quer.rid;
    //接收日期的参数
    this.initializationDate(this.quer.conditions);
    //判断是历史租客还是当前租客
    if (this.quer.id == 1 || this.quer.id == 2) {
      this.quer.existence = "当前业主/租客";
    } else {
      this.quer.existence = "历史业主/租客";
    }
    this.getLogList();
  },
  components: {
    CSTable,
    CSBreadcrumb,
    CSTabBar,
    Pagination,
    CSSelect,
  },
  methods: {
    getLogListDown(e) {
      if (e.keyCode == 13) {
        this.getLogList();
      }
    },
    getLogList(pageNo = 1, pageSize = 10) {
      //历史租客水表
      if (this.quer.id == 3 || this.quer.id == 1) {
        this.$fly
            .post(waterUmulativeConsumptionUrl, {
              rid: this.rid || '', //记录id
              id: this.quer.meterId,
              type: this.quer.type,
              pageSize: pageSize, //页面大小
              pageNo: pageNo, //页码
              startDate: this.cumulativeList.startTime, //起始时间
              endDate: this.cumulativeList.endTime, //结束时间
            })
            .then((res) => {
              if (res.code != 0) {
                return;
              }
              if (pageNo == 1) {
                this.$vc.emit(this.$route.path, "pagination", "init", {
                  total: res.data.total || res.data.datas.length,
                  pageSize: pageSize,
                  currentPage: pageNo,
                });
              }
              this.waterList = res.data;
            });
      } else {
        this.$fly
            .post(waterUmulativeConsumptionUrl, {
              rid: this.rid || '', //记录id
              id: this.quer.meterId,
              type: this.quer.type,
              pageSize: pageSize, //页面大小
              pageNo: pageNo, //页码
              startDate: this.cumulativeList.startTime, //起始时间
              endDate: this.cumulativeList.endTime, //结束时间
            })
            .then((res) => {
              if (res.code != 0) {
                return;
              }
              if (pageNo == 1) {
                this.$vc.emit(this.$route.path, "pagination", "init", {
                  total: res.data.total || res.data.datas.length,
                  pageSize: pageSize,
                  currentPage: pageNo,
                });
              }
              this.electricityList = res.data.datas;
            });
      }
    },
    //初始化日期
    initializationDate(date) {
      if (!date) {
        return;
      }
      this.cumulativeList.startTime = date.startTime;
      this.cumulativeList.endTime = date.endTime;
    },
  },
};
</script>

<style>
</style>
